.statsHeader {
  font-size: 18px;
  margin-left: 10px;
  margin-top: 15px;
}

.statsContainer {
  margin-bottom: 20px; /* Space below the container */
  display: flex;
}

.statsRow {
}

.homeContainer {
  margin: 0 auto;
  padding-bottom: 70px;
}

.searchBar {
  margin: 0 auto;
}

.homeContainer {
  width: 90%;
}

@media screen and (min-width: 1000px) {
  .searchBar {
    width: 70%;
  }

  .homeContainer {
    width: 90%;
  }
}

.button {
  background-color: transparent;
  border: 1px solid #01697c;
  color: #01697c;
}

.button:hover {
  background-color: #01697c;
  color: white;
}

.button:focus,
.button:active {
  outline: none;
}

.ellipsisText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.searchResultsButton:active,
.searchResultsButton:focus {
  outline: none !important;
  box-shadow: none !important;
}

.banner {
  background-color: #75c2c2;
  color: #fff;
  padding: 15px 20px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.banner .ctaButton {
  background-color: #01697c;
  color: #fff;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
  white-space: nowrap;
  margin-left: 10px;
}

.banner .ctaButton:hover {
  background-color: #0a5a68;
}

.bannerText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.bannerBodyText {
  flex-grow: 1;
  margin: 0;
}

.bannerHeader {
  color: black;
  font-weight: medium;
  font-size: 1.5em;
  flex-grow: 1;
  margin: 0;
}

.paginationContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.paginationButton {
  background-color: #01697c;
  border: 1px solid #01697c;
  color: white;
  padding: 10px 20px;
  width: 70px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.paginationButton:hover {
  background-color: white;
  color: #01697c;
}

.paginationButton:focus {
  outline: none;
}

.paginationButton:disabled {
  background-color: #cccccc;
  border: 1px solid #cccccc;
  cursor: not-allowed;
}

.paginationInfo {
  margin: 0 15px;
  font-size: 16px;
}

@media screen and (max-width: 768px) {
  .banner {
    background-color: #a4d4e1;
    padding: 15px 13px;
  }

  .bannerBodyText {
    display: none;
  }

  .bannerHeader {
    font-size: 0.9em;
  }

  .banner .ctaButton {
    padding: 10px 10px;
    font-size: 0.8em;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }

  .paginationContainer {
    display: none;
  }

  .homeContainer {
    padding-bottom: 20px;
  }
}
