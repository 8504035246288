.button {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  margin-bottom: 25px;
  margin-right: 20px;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #01697c;
  color: white;
}
