.blogContainer {
  padding: 20px;
  padding-bottom: 60px;
  max-width: 800px;
  margin: 0 auto;
}

.blogContainer ul {
  list-style-type: disc;
  padding-left: 20px;
}

.blogContainer ul li {
  position: relative;
}

.blogContainer img[src="https://www.homeknown.app/unsplashLogo.png"]
{
  float: right;
  margin-left: 10px !important;
  margin-top: 4px !important;
  width: 66px !important;
  height: 15.1504px !important;
}

.blogContainer table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
}

/* Style the table cells */
.blogContainer th,
.blogContainer td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Style the table headers */
.blogContainer th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Style for alternating row colors */
.blogContainer tr:nth-child(even) {
  background-color: #f9f9f9;
}

.blogContainer tr:hover {
  background-color: #f1f1f1;
}

.blogTitle {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.blogAuthor {
  font-size: 1.2em;
  margin-bottom: 20px;
  color: #666;
}

.blogContent {
  line-height: 1.6;
  font-size: 1.1em;
  color: #444;
}

.blogContent img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.blogContent p {
  margin-bottom: 15px;
}
