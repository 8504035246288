.supportContainer {
  height: calc(100vh - 81px);
  padding-top: 10px;
  width: 65%;
  margin: auto;
}

@media (max-width: 768px) {
  .supportContainer {
    width: 90%;
  }
}
