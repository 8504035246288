.swiper {
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.swiperSlide {
  text-align: right;
  font-size: 11px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  display: block;
  width: 100%;
  height: 100%; /* Adjust based on your needs */
  object-fit: cover;
  border-radius: 15px;
}
.imageVisible {
  display: block;
}

.imageHidden {
  display: none;
}

.imageContainer {
  width: 100%;
  height: 95%;
  border-radius: 15px;
  overflow-y: hidden;
  margin-bottom: 5px;
}

.deleteButton {
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  color: white;
  border: none;
  padding: 5px 10px;
  width: 40px;
  height: 40px;
  cursor: pointer;
  z-index: 10000;
}

@media (max-width: 767px) {
  .imageContainer {
    height: 90%;
  }
}

.unsplashLogo {
  color: black;
  height: 1.1em; /* Sets the height of the logo to match the font size of the text */
  width: auto; /* Keeps the logo's aspect ratio intact */
  margin-left: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
}
.unsplashLogoContainer {
  display: inline-block;
  vertical-align: middle;
}
