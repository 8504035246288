.button {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  margin-bottom: 25px;
  margin-right: 20px;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #01697c;
  color: white;
}

.header {
  text-align: left;
}

.container {
  height: calc(100vh - 81px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: hidden;
}

.content {
  margin-top: 0px;
  width: 90%;
  margin-bottom: 0px;
}

@media (min-width: 768px) {
  .content {
    width: 65%;
    margin-bottom: 200px;
  }
}
