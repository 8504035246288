.loadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.logo {
  width: 400px;
  height: auto;
  margin-bottom: 40px;
}

.loadingText {
  margin-bottom: 30px;
  font-size: 24px;
  text-align: center;
}

.loadingDots {
  display: flex;
  gap: 10px;
}

/* LoadingScreen.module.css */

@keyframes wave {
  0%,
  60%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(
      -15px
    ); /* Adjust the value to control the height of the wave */
  }
}

.dot {
  width: 12px; /* Size of the dot */
  height: 12px; /* Size of the dot */
  margin: 0 5px; /* Space between dots */
  background-color: #000; /* Color of the dot */
  border-radius: 50%;
  display: inline-block;
  animation: wave 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: -0.4s;
}

.dot:nth-child(2) {
  animation-delay: -0.3s;
}

.dot:nth-child(3) {
  animation-delay: -0.2s;
}
