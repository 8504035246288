.filterBar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: white;
}

.filterItem {
  margin: 0 10px;
}

.icon {
  font-size: 12px;
}

.filterSelect {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  max-width: 200px;
  height: 100%;
  font-weight: normal;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.applyButton {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  max-width: 200px;
  margin-bottom: 5px;
  margin-right: 20px;
  font-weight: normal;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.applyButton:hover {
  background-color: #01697c;
  color: white;
}

.dropdownContent {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 2000;
}

.optionGroup {
  margin-bottom: 10px;
}

/* Style adjustments for selected buttons */
.selected {
  background-color: #01697c !important;
  color: white !important;
}

.innerButton {
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.innerButton:hover {
  background-color: #f8f9fa;
}

.innerButton:focus {
  outline: none;
}

.filterButton {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  max-width: 200px;
  min-width: 105px;
  margin-top: 10px;
  white-space: nowrap;
  font-weight: normal;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.filterButton:hover,
.filterSelect:hover {
  background-color: #01697c;
  color: white;
}

.filterButton:focus,
.filterSelect:focus {
  outline: none;
}

.filterLabel {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.priceRangeGroup,
.sqftRangeGroup {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px; /* Adjust the space between the min and max dropdowns */
}

.priceRangeGroup > div,
.sqftRangeGroup > div {
  flex: 1;
}

.label {
  display: block;
  margin-bottom: 4px; /* Space between the label and the select box */
}

.select {
  width: 135px;
  padding: 8px; /* Adjust the padding to match the design */
  border: 1px solid #ccc; /* Border color */
  border-radius: 4px; /* Border radius for rounded corners */
}

/* Style for the Apply/Close button if needed */
.applyButton {
  margin-top: 16px; /* Space above the button */
  padding: 8px 16px; /* Padding inside the button */
  border: none;
  background-color: #01697c !important;
  color: white !important;
  border-radius: 4px; /* Border radius for rounded corners */
  cursor: pointer; /* Cursor for the button */
}

.applyButton:hover {
  background-color: #0056b3; /* Darker shade on hover */
}

.radioLabel,
.checkboxLabel {
  display: block;
  margin: 5px 0;
  cursor: pointer;
}

.radioInput,
.checkboxInput {
  margin-right: 10px;
}
