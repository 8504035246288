.cityContainer {
  padding-top: 20px;
  margin-bottom: 30px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  background-color: white;
  width: 100%;
  height: 375px;
}

.heartButton {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.rankingImage {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.ranking {
  font-weight: bold;
  margin-right: 10px;
}

.cityDetails {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.header {
  font-size: 16px;
  margin-bottom: 0px;
}

@media screen and (max-width: 768px) {
  .cityContainer {
    padding-bottom: 0px;
    margin-bottom: 10px;
    height: 360px;
  }
}
