.logo {
  width: 40px;
  margin-right: 15px;
}

.nav {
  display: none;
  z-index: 1000;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: white !important;
  border-bottom: 0.8px solid rgba(128, 128, 128, 0.3);
}

.navBody {
  background-color: white !important;
}

@media (min-width: 768px) {
  .navBody {
    width: 90%;
    margin: auto;
    padding-left: 0px;
    padding-right: 0px;
  }
  .nav {
    display: flex;
  }
}

.button:hover {
  background-color: #01697c;
  color: white !important;
}

.button {
  font-size: 15px;
  font-weight: 400;
  color: rgb(56, 56, 56) !important;
}

.brand {
  font-size: 18px;
}

.button:focus,
.button:active,
.userCircle:active,
.userCircle:focus {
  outline: none !important;
  box-shadow: none;
}

.userCircle,
.userCircle:hover {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  background-color: white;
  border: 0.1px solid #01697c !important;
  color: #01697c !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.dropdown {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  border: none;
  font-size: 15px;
  font-weight: bold !important;
  width: 200px;
}

.dropdownItem {
  font-size: 15px;
  font-weight: 400 !important;
}

.bottomNavShow {
  display: flex; /* or 'block' depending on your layout */
  transform: translateY(0); /* Start at 0 movement from the Y-axis */
}

/* Hidden state for the bottom nav with animation */
.bottomNavHide {
  display: flex; /* or 'block' depending on your layout */
  transform: translateY(100%); /* Move it down out of view */
}
