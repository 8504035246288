.explorePageContainer {
}

h1 {
  color: #333;
  text-align: center; /* Ensure centered alignment on mobile */
}

button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 4px;
  transition: background-color 0.3s;
  max-width: 100%; /* Ensure the button doesn't overflow its container */
  box-sizing: border-box;
}

button:hover {
  background-color: #0056b3;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  h1 {
    font-size: 24px; /* Reduce font size for mobile */
  }

  button {
    font-size: 14px; /* Adjust button font size for mobile */
    padding: 8px 16px;
  }
}
