.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 65%;
  margin: auto;
  margin-top: 50px;
}

.gridItem {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  height: 200px;
  text-align: left;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  position: relative;
}

.chevron {
  display: none;
}

@media (hover: hover) {
  .gridItem:hover {
  }
}

.icon {
  font-size: 22px;
  margin-bottom: 16px;
}

.title {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 500;
}

.description {
  color: rgb(87, 87, 87);
  font-size: 15px;
}

.logout,
.login {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  margin-top: 20px;
  margin-bottom: 100px;
  font-weight: normal;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
  text-align: center;
}

.logout:hover,
.login:hover {
  background-color: #01697c; /* Changes background on hover, optional */
  color: white; /* Changes text color on hover, optional */
}

@media (min-width: 768px) {
  .login,
  .logout {
    visibility: hidden;
  }
}

/* Mobile view */
@media (max-width: 768px) {
  .gridContainer {
    grid-template-columns: 1fr;
    width: 90%;
    padding: 0 15px;
    grid-gap: 0;
    margin-top: 20px;
  }

  .chevron {
    display: block;
    font-size: 16px;
    color: #017c8c;
    margin-left: 10px;
  }

  .gridItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ddd;
    border-radius: 0px;
    box-shadow: none;
    height: auto;
  }

  .icon {
    display: none;
  }

  .title,
  .description {
    display: block;
  }

  .title {
    font-size: 16px;
  }

  .description {
    font-size: 13px;
  }
}
