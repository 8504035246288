.popupClick {
  cursor: pointer;
}

.popup {
  width: 300px;
  height: 100px;
}

.photo {
  width: 100%;
  height: 210px;
}

.address {
  font-size: 16px;
  margin-top: 5px !important;
  margin-bottom: 0px !important;
  font-weight: 450;
}

.addressContainer {
  margin-bottom: 0px !important;
  height: 3.5em;
}

.marker {
}

.buttonContainer {
  display: flex;
  justify-content: start;
  width: 100%;
}

.mapContainer {
  margin-bottom: 30px !important;
  width: 100%;
  margin: auto;
}

.filterButton {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  max-width: 200px;
  margin-bottom: 20px;
  margin-right: 20px;
  font-weight: normal;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.filterButton:hover {
  background-color: #01697c;
  color: white;
}

.filterButton:focus {
  outline: none;
}

@media (max-width: 768px) {
  .filterBar {
    display: none;
  }

  .popup {
    width: 215px;
    height: 110px;
  }

  .photo {
    height: 150px;
  }

  .address {
    font-size: 14px;
  }

  .addressContainer {
    height: 3em;
  }
}

.filterBar {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100%;
  height: auto;
  padding: 15px;
  text-align: center;
  margin-bottom: 40px;
}

.dropdown {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.circleMapMarker {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  border: 2px solid white; /* Adjust color and border size as needed */
  border-radius: 50%;
  background-color: #8b0000;
  display: flex;
  align-items: center;
  justify-content: center;
}
