body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* ProseMirror styles */
.ProseMirror {
  min-height: 600px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fafafa;
}

.ProseMirror p {
  margin: 0;
  padding: 0.5em 0;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  margin: 0;
  padding: 0.5em 0;
}

.ProseMirror blockquote {
  border-left: 4px solid #ccc;
  padding-left: 1em;
  margin-left: 0;
  color: #666;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

.ProseMirror a {
  color: #007bff;
  text-decoration: none;
}

.ProseMirror a:hover {
  text-decoration: underline;
}

.ProseMirror table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
}

/* Style the table cells */
.ProseMirror th,
.ProseMirror td {
  border: 1px solid #ddd;
  padding: 8px;
}

/* Style the table headers */
.ProseMirror th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Style for alternating row colors */
.ProseMirror tr:nth-child(even) {
  background-color: #f9f9f9;
}

.ProseMirror tr:hover {
  background-color: #f1f1f1;
}

.ProseMirror img {
  max-width: 100%;
  height: auto;
}

img.focused-image {
  outline: 2px solid #00f; /* Change the color and width as needed */
}
