.heartIcon {
  fill: none;
  stroke: #231f20;
  stroke-miterlimit: 10;
  stroke-width: 15px;
  width: 25px;
  height: 25px;
  margin: 5;
  padding: 5;
}
