/* Slider Styles */

/* Adjusting the width of the slider */
.rc-slider {
  width: 100%; /* or whatever width you want */
  margin: 0 auto; /* centers the slider */
}

/* Styling the track (the part of the slider that's filled in) */
.rc-slider-track {
  background-color: #78cce8; /* matching the color of the buttons from earlier */
}
.rc-slider-mark-text {
  font-size: 14px !important; /* Adjust the size as needed and use !important cautiously */
  width: 150px;
}

/* Styling the rail (the background of the slider) */
.rc-slider-rail {
  background-color: #e0e0e0; /* a light gray */
  height: 6px !important; /* matching the track height */
}

.rc-slider-track,
.rc-slider-tracks {
  height: 6px !important;
}

.rc-slider-dot {
  height: 10px !important;
  width: 10px !important;
  bottom: -2px !important;
  padding-top: 5px !important;
}

.rc-slider-step {
  height: 6px !important;
}

/* Styling the handle (the draggable part) */
.rc-slider-handle {
  width: 25px !important; /* making the handle larger */
  height: 25px !important;
  border: solid 2px #007bff; /* a border to match the track color */
  background-color: #fff; /* a white handle */
  box-shadow: none; /* removing any shadow */
  margin-top: -9.5px !important; /* adjusting margin to vertically center the handle */
}

/* Styling the handle when it's being dragged */
.rc-slider-handle:active {
  border-color: #0056b3; /* a slightly darker blue */
  box-shadow: 0 0 5px #0056b3; /* adding a subtle shadow for a "pressed" effect */
}

.styles_formCheckInput {
  display: none;
}

.styles_formCheckInput:checked + .styles_formCheckLabel::before {
  background-color: yourDesiredColor;
  /* you can even add a small circle inside for radio button style */
}
