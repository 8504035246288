.sidebar {
  top: 0; /* Stay at the top */
  left: 0;
  bottom: 0;
  overflow-x: hidden; /* Disable horizontal scroll */
  padding-top: 20px;
  background-color: white !important;
  z-index: 900;
}

.sidebar a {
  display: block;
  color: black;
  padding: 10px;
  text-decoration: none;
}

.sidebar a.active {
  background-color: #007bff;
  color: white;
}

@media (min-width: 768px) {
  .sidebar {
    height: 100vh; /* Full height */
  }
}

.activeLink {
  background-color: lightgray;
  color: white;
}

.mobileNav {
  display: flex;
  background-color: white !important;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  padding: 10px 0;
  font-size: 15px;
}
