.cityPage {
  display: flex;
  flex-direction: column;
  margin: auto;
  align-items: center;
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  height: 100%;
  width: 75%;
}

.container {
  height: 100%;
  margin-left: 200px;
}

.navContainer {
  background-color: white !important;
}

@media (min-width: 768px) {
  .navContainer {
    max-width: 250px;
  }
}

.card {
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 100%;
  height: auto;
  padding: 15px;
  text-align: center;
  margin-bottom: 40px;
}

.housingChartContainer {
  width: 100%;
  height: auto;
  max-height: 500px;
  margin-bottom: 90px;
}

.chartContainer {
  width: 100%;
  height: 650px;
}

.airContainer {
  width: 100%;
  height: 450px;
}

.buttonContainer {
  display: flex;
  justify-content: start;
  gap: 10px;
  padding: 15px;
}

/* Media query for mobile devices */
@media (max-width: 450px) {
  .cityPage {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
  }
}

.cityPage h1 {
  margin-bottom: 20px;
  color: #333;
  font-size: 2em;
}

.btnDropdown {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  margin-bottom: 25px;
  margin-right: 20px;
  background-color: transparent;
  color: #01697c;
  border: 1px solid #01697c;
  padding: 8px 16px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

@media (max-width: 1000px) {
  .btnDropdown {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.btnDropdown:focus {
  outline: none;
}

.btnDropdown:hover {
  background-color: #01697c;
  color: white;
}

/*# Weather Forecast */

@keyframes rotateSun {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.sun-icon {
  display: inline-block;
  animation: rotateSun 10s linear infinite;
  font-size: 48px; /* Adjust size as needed */
}

.heartButton,
.heartButton:focus,
.heartButton:hover {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  margin: 5px;
}

.backButton {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 16px;
}

@media (min-width: 768px) {
  .backButton {
    display: inline-flex;
    white-space: nowrap;
    align-items: center;
    margin-right: 20px;
    font-size: 16px;
  }
}

.backButton:hover {
  background-color: #01697c;
  color: white;
}

.backButton:focus {
  outline: none;
}

@media (max-width: 767px) {
  .backButton {
    margin-left: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .headerContainer {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
    margin-bottom: 15px;
    margin-left: 10px;
    text-align: center;
    margin-top: 15px;
  }
}

.cityName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  gap: 10px;
  padding-left: 0px;
  padding-right: 0px;
}

.cityPhotos {
  width: 100%;
  height: 450px;
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  .cityName {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .cityPhotos {
    width: 100%;
    height: 300px;
  }
}

.header {
  margin-bottom: 0px;
  font-size: 24px;
}

@media (max-width: 768px) {
  .header {
    font-size: 22px;
    font-weight: 500;
  }
}

/* overview fact section */
.factsContainer {
  width: 100%;
  margin-bottom: 20px;
  margin: auto;
}

.factCard {
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: #f9f9f9;
  border-radius: 8px;
  height: 120px;
  padding: 12px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (min-width: 1000px) {
  .factCard {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 100px;
    background-color: #f9f9f9;
    border-radius: 8px;
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
}

.icon {
  font-size: 24px;
  margin-right: 16px;
}

.fact {
  display: flex;
  flex-direction: column;
}

.factName {
  font-size: 14px;
  color: #666;
}

.factValue {
  font-size: 14px;
  color: #333;
}
