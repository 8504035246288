.pageContainer {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: start;
  height: calc(100vh - 81px);
  width: 90%;
}

.card {
  margin: 0 auto;
  margin-top: 50px;
  width: 100%;
}

.btn {
  background-color: white;
  color: #01697c;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #01697c;
}

.btn:hover {
  background-color: #01697c;
  color: white;
}

.btn:active,
.btn:focus {
  outline: none;
}

@media (min-width: 768px) {
  .pageContainer {
    width: 70%;
  }
  .card {
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
}
