.select {
  z-index: 2000;
  width: 100%;
  margin-bottom: 20px;
}

.searchContainer {
  display: flex;
  justify-content: start;
  align-items: center;
}

@media (max-width: 768px) {
}

.bulletList {
  list-style-type: disc;
  margin-left: 20px;
  margin-bottom: 20px;
}

.select {
  width: 100%;
}

.description {
  margin-top: 20px;
}

.description h2 {
  font-size: 2em;
  margin-bottom: 20px;
}

.description h4 {
  font-size: 1.5em;
  margin-top: 20px;
  margin-bottom: 10px;
}

.description li {
  margin-bottom: 10px;
  line-height: 1.6;
}

.description li strong {
  color: #333;
}

.description li:before {
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}
