.blogPostContainer {
  padding: 20px;
  padding-bottom: 60px;
  max-width: 800px;
  margin: auto;
}

.blogPostContainer ul {
  list-style-type: disc;
  padding-left: 20px;
}

.blogPostContainer ul li {
  position: relative;
}

.blogPostContainer img[src="https://www.homeknown.app/unsplashLogo.png"]
{
  float: right;
  margin-left: 10px !important;
  margin-top: 4px !important;
  width: 66px !important;
  height: 15.1504px !important;
}

.thumbnail {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.blogPostContainer h2 {
  font-size: 34px;
}
