.preferenceContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropZoneContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
}

.dropzone:hover {
  border-color: #888;
}

.selected {
  background-color: #01697c;
  color: white;
  border-color: #01697c;
}

.dropzoneTitle {
  margin-top: 8px;
  margin-bottom: 0px;
  font-size: 12px;
  text-align: center;
}
