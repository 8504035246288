.recommendationsHeader {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
}

.recommendations {
  margin-bottom: 20px; /* Space below the container */
  display: flex;
}

.pageContainer {
  margin: 0 auto;
  width: 90%;
  padding-top: 10px;
}

@media screen and (min-width: 768px) {
  .pageContainer {
    padding-top: 20px;
  }
}
