.loadingScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 100vh;
  background-color: #fff;
  padding: 20px;
  position: relative;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 30px;
  margin-top: 140px;
  border-radius: 15px;
  width: 8%;
  height: 46.3%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(250, 250, 250);
  animation: pulsate 1.75s infinite;
}

.photo {
  width: 65%;
  height: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 15px;
  background-color: rgb(250, 250, 250);
  animation: pulsate 1.75s infinite;
}

.description {
  width: 65%;
  height: 150px;
  border-radius: 15px;
  margin-bottom: 50px;
  background-color: rgb(250, 250, 250);
  animation: pulsate 1.75s infinite;
}

.details {
  height: 100px;
  width: 65%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.contentBlock {
  background-color: rgb(250, 250, 250);
  width: 23%;
  height: 100%;
  border-radius: 15px;
  margin-bottom: 20px;
  animation: pulsate 1.75s infinite;
}

@keyframes pulsate {
  0% {
    background-color: rgb(250, 250, 250);
  }
  50% {
    background-color: rgb(235, 235, 235);
  }
  100% {
    background-color: rgb(250, 250, 250);
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }

  .details,
  .photo,
  .description {
    width: 95%;
  }

  .photo {
    height: 370px;
    margin-top: 22px;
  }

  .header {
    width: 90%;
    border-radius: 15px;
    margin-top: 60px;
    height: 30px;
    background-color: rgb(250, 250, 250);
    animation: pulsate 1.75s infinite;
  }

  .nav {
    width: 90%;
    border-radius: 15px;
    margin-top: 20px;
    height: 40px;
    background-color: rgb(250, 250, 250);
    animation: pulsate 1.75s infinite;
  }
}
