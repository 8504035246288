.placeholderContainer {
  padding-top: 20px;
  margin-bottom: 28px;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
  position: relative;
  cursor: pointer;
  background-color: white;
  width: 100%;
  height: 375px;
}

.imageContainer {
  width: 100%;
  height: 90%;
  border-radius: 15px;
  margin-top: 29px;
  margin-bottom: 5px;
  background-color: rgb(250, 250, 250);
  animation: pulsate 1.75s infinite;
}

@keyframes pulsate {
  0% {
    background-color: rgb(250, 250, 250);
  }
  50% {
    background-color: rgb(235, 235, 235);
  }
  100% {
    background-color: rgb(250, 250, 250);
  }
}

@media screen and (max-width: 768px) {
  .placeholderContainer {
    padding-bottom: 0px;
    max-height: 330px;
    margin-bottom: 36.5px;
  }

  .imageContainer {
    height: 96%;
  }
}
