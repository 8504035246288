.bottomNav {
  display: none;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 65px;
  padding: 0.75rem 0;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  justify-content: space-around;
  align-items: center;
  transition: transform 0.3s ease-in-out;
}

.navItem a {
  text-decoration: none;
  color: #333; /* Or any color you prefer */
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 4em;
}

.icon {
  font-size: 22px;
}

.navText {
  font-size: 10px;
}

.navItem svg {
  margin-bottom: 0.25rem;
}

/* Add responsiveness if needed */
@media (max-width: 768px) {
  .bottomNav {
    display: flex;
  }
}
