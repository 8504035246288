/* BlogPostEditor.module.css */
.editorContent {
  padding: 0 20px;
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.editorPreviewContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1;
  overflow: hidden;
  padding-bottom: 70px;
}

.editorPreviewContainer ul {
  list-style-type: disc;
  padding-left: 20px;
}

.editorPreviewContainer ul li {
  position: relative;
}

.editorPreviewContainer img[src="https://www.homeknown.app/unsplashLogo.png"]
{
  float: right;
  margin-left: 10px !important;
  margin-top: 4px !important;
  width: 66px !important;
  height: 15.1504px !important;
}

.editorPreviewContainer h2 {
  font-size: 34px;
}

.editorContainer,
.previewContainer {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.previewContainer {
  overflow-y: auto;
}

img.focused-image {
  outline: 2px solid #00f; /* Change the color and width as needed */
}

.btnContainer {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-left: 25%;
}

.toolbar button {
  background-color: #01697c;
  border: 1px solid white;
  border-radius: 15px;
}

.btnContainer button {
  background-color: #01697c;
  border-radius: 6px;
}

.inputFields {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}
