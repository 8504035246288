.heartIcon {
  fill: none;
  stroke: #231f20;
  stroke-miterlimit: 10;
  stroke-width: 15px;
  width: 15px;
  height: 15px;
  margin: 5;
  padding: 5;
}

.btnText {
  color: black;
  margin-bottom: 0px;
  margin-left: 7px;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn:focus,
.btn:active {
  outline: none;
}
